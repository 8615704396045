import React, { useEffect, useState } from 'react'
import Home_Header from '../../inc/home-header/Home_Header'
import Modal from '../../inc/modal/Modal'
import Left_Panel from '../left-panel/Left_Panel'
import { fun_zone_list } from '../../../services/user_apis'
import { useNavigate, useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import $ from 'jquery'
import { useTranslation } from 'react-i18next'

const Fun_Zone = ({ socketData }) => {
    const { t } = useTranslation();
    const [fun_zone, setFun_zone] = useState([])
    const [type, setType] = useState("game");
    let navigate = useNavigate();
    let location = useLocation();
    // console.log("location  ====> ", location)
    useEffect(() => {
        if (location.state) {
            setType(location.state.key)
            $(".nav-link").removeClass("active")
            $("." + location.state.activeTab).addClass("active")
        }
        const fun_zone_list2 = async () => {

            try {
                const response = await fun_zone_list(location.state ? location.state.key : "game")
                // console.log(response, "yash s te")
                if (response.code === 1) {
                    setFun_zone(response.data)
                } else {
                    setFun_zone([])
                }
            }
            catch (e) {
                navigate('/')
            }

        }
        fun_zone_list2()
    }, [])

    const fun_zone_list1 = async (value) => {
        setType(value);
        const fun_zone_list_wrap = async () => {
            const data = await fun_zone_list(value)
            if (data.code === 1) {
                setFun_zone(data.data)
            } else {
                setFun_zone([])
            }
        }
        fun_zone_list_wrap()
    }

    return (
        <React.Fragment>


            <div className="content">
                <div className="left-portion">
                    <Left_Panel />
                </div>
                <div className="middle-portion">
                    <Home_Header socketData={socketData} />
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <ul className="nav nav-tabs funzone-tab">
                                    <li className="nav-item" onClick={() => fun_zone_list1("game")} >
                                        <a className="nav-link gameTab active" data-toggle="tab" value="coding">{t('Games')}</a>
                                    </li>
                                    <li className="nav-item" onClick={() => fun_zone_list1("cartoon")}>
                                        <a className="nav-link cartoonTab" data-toggle="tab" value="coding">{t('Cartoons')}</a>
                                    </li>
                                    <li className="nav-item" onClick={() => fun_zone_list1("well_being")}>
                                        <a className="nav-link wellBeingTab" data-toggle="tab" value="coding">{t('Wellbeing')}</a>
                                    </li>
                                    <li className="nav-item" onClick={() => fun_zone_list1("meme")}>
                                        <a className="nav-link memesTab" data-toggle="tab" value="coding">{t('Memes')}</a>
                                    </li>
                                    <li className="nav-item" onClick={() => fun_zone_list1("video")}>
                                        <a className="nav-link memesTab" data-toggle="tab" value="coding">{t('Videos')}</a>
                                    </li>
                                    <li className="nav-item" onClick={() => fun_zone_list1("music")}>
                                        <a className="nav-link videoTab" data-toggle="tab" value="coding">{t('Music')}</a>
                                    </li>
                                    {/* <li className="nav-item" onClick={() => fun_zone_list1("coding")}>
                                        <a className="nav-link codingTab" data-toggle="tab" value="coding">{t('Coding')}</a>
                                    </li> */}
                                </ul>
                                <div className="tab-content">
                                    <div className="tab-pane active" id="games">
                                        <div className="row mt-3">
                                            {(fun_zone.length > 0) ? fun_zone.map((value, i) => (
                                                <div className={type!=="meme" ? "col-md-6" : "col-md-4 justify-content-center"} key={i}>
                                                    <div className="game">
                                                        <div className="box">
                                                            {type!=="meme" ? <>
                                                                <img src={value.fun_zone_cover_image_full_path} />
                                                                <div className="text">
                                                                    <h6>
                                                                        {value.fun_zone_title}
                                                                    </h6>
                                                                    <p>
                                                                        {value.fun_zone_description}
                                                                    </p>
                                                                    <Link to="/funzone-details" state={{ funzonedata: value }} className="play web">
                                                                        {t('Play')}
                                                                    </Link>
                                                                </div> 
                                                            </> : <div className="mx-auto">
                                                                {/* <Link to="/funzone-details" state={{ funzonedata: value }}><h6 className="text"><center>{value.fun_zone_title}</center></h6></Link> */}
                                                                <Link to="/funzone-details" state={{ funzonedata: value }}><img src={value.fun_zone_cover_image_full_path} /></Link>
                                                            </div>}
                                                        </div>
                                                    </div>
                                                </div>
                                            )) : <div className="col-md-12"><center><h4>{t('Coming Soon')}</h4></center></div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal />
        </React.Fragment>
    )
}

export default Fun_Zone
